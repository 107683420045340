.iconAreaCopy{
    align-content: center;
    text-align: right;
}

.iconAreaCopy img{
    cursor: copy;
}

em.highlightedBreadcrumb{
    color: var(--bs-link-color);
    font-style: normal;
}

.copySection.accountDetailsSection{
    background-color: var(--bs-light);
    padding: 10px;
}

.copySection.addMoneySection{
    background-color: #F6F5F4;
    padding: 15px;
}

.transaction-pagination {
    .page-item.active .page-link{
        background-color: var(--bs-link-color);
        border-color: var(--bs-link-color);
    }
    .page-link{
        border-color: var(--bs-link-color);
        border-radius: 0px !important;
    }
    .disabled .page-link{
        background-color: transparent;
    }
    .pagination-arrow .page-link{
        padding-left: 8px;
        padding-right: 8px;
    }
    .page-item.disabled{
        opacity: 0.6;
    }
}