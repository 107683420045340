.accountBalance {
  font-size: 28px;
  font-weight: 600;
  color: var(--bs-primary-one);
}

.darkGrayColor {
  color: var(--bs-bodytext-darkthree);
  font-size: 14px;
}

.accountName {
  font-size: 18px;
  font-weight: 500;
}

.yourCurrentBalance {
  font-size: 20px;
}

.yourCurrentBalance .balance {
  font-size: 28px;
}

//Dashboard Buttons

.btnTextSize {
  font-size: 14px;
}

// Dicover more section

.discoverHeading {
  font-size: 24px;
  font-weight: 500;
}

.discoverProductHeading {
  font-size: 18px;
  font-weight: 600;
}

.discoverProductPara {
  font-size: 14px;
  font-weight: 400;
}

.discoverProductLink {
  padding-left: 16px;
}

.discoverProductLi::marker {
  color: var(--bs-primary-one);
}

//Account details screen custom css
.copyTextColor {
  color: var(--bs-bodytext-darktwo);
  font-size: 14px;
  font-weight: bold;
}

.transactionDate {
  color: var(--bs-bodytext-darktwo);
  font-size: 14px;
}

.transactiontitle {
  color: var(--bs-bodytext-darkone);
  font-size: 18px;
}

.with100Percent {
  width: 100%;
}

.transactionAmount {
  font-size: 24px;
  color: var(--bs-bodytext-darkone);
}

.lastLoginText {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lastLoginTextContainer {
  margin-right: 1em;
}

// fixed term deposit

.note {
  font-size: 14px;
  font-weight: 400;
}

.note ul {
  color: var(--bs-primary-one);
}

//Add Money Screen
.addMoneyCopyTextColor {
  color: var(--bs-bodytext-darktwo);
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

// account widget

.accountAlert {
  background-color: #e6eeef;
}

.accountAlertDate {
  font-size: 16px;
  color: #00505a;
  font-weight: 700;
}

.transactionCancelTitle {
  font-size: 18px;
  font-weight: 400;
}

.transactionCancelDiscription {
  font-size: 16px;
  font-weight: 300;
  padding-top: 16px;
  padding-bottom: 16px;
}
