/* Common */

:root {
  --bs-primary-one: #00505a;
  --bs-primary-two: #ca7158;
  --bs-primary-three: #321c16;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-white_opacity60: #ffffff99;
  --bs-light: #fafaf9;
  --bs-bodytext-darkone: #232323;
  --bs-bodytext-darktwo: #4f4f4f;
  --bs-bodytext-darkthree: #7b7b7b;
  --bs-link-color: #00505a;
  --bs-link-color-light: #f6f5f4;
  --bs-highlight-color: #00505a;
  --bs-border_one: #ced4da;
  --bs-border_two: #a7a7a7;
  --bs-brand-gradient: linear-gradient(
    270deg,
    #003036 0%,
    #004048 50%,
    #00505a 100%
  );
  --bs-italianfontcolor: #ccdcde;
  --bs-secondary-color: #36737a;

  /* Wizard Variables */

  --bs-step-next-fill: #f2f6f7;
  --bs-step-next-border: #f2f6f7;
  --bs-step-next-text: rgba(0, 64, 72, 0.8);
  --bs-step-current: #00505a;
  --bs-step-current-border: #00505a;
  --bs-step-current-text: #fff;
  --bs-step-complete: #00505a;
  --bs-step-complete-tick: #fff;
  --bs-step-complete-border: #00505a;

  --bs-step-next-line: #ccdcde;
  --bs-step-completed-line: #00505a;

  /* form controls variable */
  --bs-radiocheckbox-border: #99b9bd;
  --bs-generic-form-field-color: #e9ecef;
  --bs-generic-form-text-color: #616060;

  /* error */
  --bs-input-error-border: #dc3545;
  --bs-input-error-generic: #dc3545;
}

/* Buttons */

.btn-primary_one {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00505a;
  --bs-btn-border-color: #00505a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004048;
  --bs-btn-hover-border-color: #004048;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003036;
  --bs-btn-active-border-color: #003036;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00505a;
  --bs-btn-disabled-border-color: #00505a;
}
.btn-primary_two {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ca7158;
  --bs-btn-border-color: #ca7158;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #975542;
  --bs-btn-hover-border-color: #975542;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #65382c;
  --bs-btn-active-border-color: #65382c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ca7158;
  --bs-btn-disabled-border-color: #ca7158;
}
.btn-primary_three {
  --bs-btn-color: #fff;
  --bs-btn-bg: #321c16;
  --bs-btn-border-color: #321c16;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #65382c;
  --bs-btn-hover-border-color: #65382c;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ca7158;
  --bs-btn-active-border-color: #ca7158;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #321c16;
  --bs-btn-disabled-border-color: #321c16;
}

.btn-primary_one-outlined {
  --bs-btn-color: #00505a;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #00505a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004048;
  --bs-btn-hover-border-color: #004048;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003036;
  --bs-btn-active-border-color: #003036;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00505a;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #00505a;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-btn-border-radius: 0;
}
