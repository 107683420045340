.heading {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
}

.subHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #232323;
}

.errorText {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.grayHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #7b7b7b;
}

.headingMedium {
  font-size: 32px;
  line-height: 37.5px;
  font-weight: 550;
}

.commonBoldHighlightedHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--bs-primary-one);
}

.informationTitleHeading {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.errorHeadingSmallStyle{
 font-weight: 400;
 font-style: italic;
 font-size: 12px;
 color: var(--bs-input-error-generic);
 margin-top: 0.1rem;
}

.errorHeadingSmallerStyle{
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  color: var(--bs-input-error-generic);
  line-height: 14.06px;
 }

 .lightWeightTitle{
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color:#232323;
  
 }