.profileTintedBox {
  height: 112px;
  border: 1px solid var(--bs-border_two);
  width: 100% !important;
}

.profileContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.initials {
  color: #000;
  position: absolute;
  left: 28px;
  font-size: 48px;
  font-weight: 400;
}

.initialsContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  background-color: #000;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -6px;
  left: 83px;
  background-color: var(--bs-secondary-color);
}

.iconImage {
  font-size: larger;
}

.fullname {
  font-weight: 400;
  font-size: 18px;
}

.myProfileSubHeading {
  font-weight: 400;
  font-size: 16px;
}

.profileLinkedAccTintedBox {
  height: 100%;
  border: 1px solid var(--bs-border_two);
}

.linkedAccountInnerBox{
  margin: 0px 32px 0px 32px;
}
