.imageStyle {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 15px;
}

.memorableWordHeader {
  font-size: 32px;
  font-weight: 600;
}

.memorableContainer {
  display: flex;
  justify-content: center;
  padding: 90px 0px 90px 0px;
}

.container {
  border: 2px solid var(--bs-border_one);
  align-content: center;
  max-width: 921px;
  width: 100%;
}

.memorableInputStyle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.errorText {
  color: var(--bs-input-error-generic) !important;
  border-color: var(--bs-input-error-generic) !important;
}

// Marketing Preferences

.marketingPrefHeading {
  font-weight: 500;
  font-size: 16px;
  color: var(--bs-primary-one);
}

.marketingPrefLabel {
  font-size: 16px;
  font-weight: 500;
  color: var(--bs-primary-one) !important;
}

.preferenceContainer {
  border: 1px solid var(--bs-radiocheckbox-border);
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.5em 1em !important;
}

.customSwitch {
  width: 50px !important;
  height: 24px;
  padding: 15px;
  appearance: none;
  background-color: var(--bs-italianfontcolor) !important;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: background-color 0.2s;
  border-color: var(--bs-italianfontcolor);
  border: none !important;
}
.customSwitch:checked {
  background-color: var(--bs-link-color) !important;
}

.customSwitch::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 0.2s;
}

.customSwitch:checked::before {
  left: calc(100% - 25px);
}

.customSwitch:focus {
  outline: none !important;
  box-shadow: none !important;
}
.customSwitch:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.customSwitch {
  appearance: none;
}

.customSwitch::-ms-check {
  display: none;
}

.label {
  font-weight: 600;
  font-size: 16px;
}
