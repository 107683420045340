.customLine {
  border: 1px solid var(--bs-border_two);
}

.genericFormFieldTextStyle {
  color: var(--bs-generic-form-text-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 6px 12px 6px 12px;
}


  .genericFormFieldStyle {
    background-color: var(--bs-generic-form-field-color);
    width: 100%;
    height: auto;
    border-bottom: 1px solid var(bs-border_one);
  }



    .loader {
      width: 19px;
      height: 19px;
      border: 3px solid var(--bs-white);
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }
  
      @keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
      } 
