.continueButtonStyle {
  display: flex;
  justify-content: flex-end;
  gap: 11px;
  margin-top: 24px;
}

.linkedAccountBoxStyle {
  background-color: var(--bs-generic-form-field-color);
  border: 1px solid var(--bs-border_one);
  padding: 6px 12px 6px 12px;
  color: var(--bs-bodytext-darktwo);
}

.linkedAccountTextStyle {
  color: var(--bs-bodytext-darkone);
  font-size: 16px;
  font-weight: var(--font-weight-semi-bold);
  line-height: 22.4px;
}

.linkedAccountValueStyle {
  color: var(--bs-bodytext-darkone);
  font-size: 16px;
  font-weight: var(--font-weight-semi-bold);
  line-height: 22.4px;
}

.linkedAccountKeyStyle {
  color: var(--bs-bodytext-darkone);
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  line-height: 22.4px;
}

.linkedDetailsStyle {
  background-color: var(--bs-link-color-light);
  height: 54px;
  display: grid;
  padding-left: 16px;
  padding-right: 16px;
}

.linkedDetailsParent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.withdrawContainer {
  max-width: 864px;
  width: 100%;
  gap: 18px;
  display: flex;
  flex-direction: column;
}

.withdrawSubHeading {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-weight-regular);
  color: var(--bs-bodytext-darktwo);
}

.boldAmount {
  font-weight: --font-weight-bold !important;
}

.noticeAlertMsg {
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  margin-top: 5px;
}
