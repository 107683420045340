/* Common */

:root {
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
}

body {
  font-family: Roboto Flex;
}

input::placeholder {
  color: #6C757D !important;
}

.font-family-Courgette {
  font-family: Courgette !important;
}
.font-playboll {
  font-family: Playball;
  font-size: 48px;
  font-weight: var(--font-weight-regular);
  line-height: 72px;
  text-align: center;
}
a {
  color: var(--bs-link-color);
  text-decoration: none;
}
.text-primary_one {
  color: var(--bs-primary-one);
}
.btn {
  border-radius: 0;
}
.btn-md,
.btn-group-md > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-font-size: 1rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
button:disabled,
[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled {
  cursor: not-allowed;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  cursor: not-allowed;
}
.form-control {
  border: var(--bs-border-width) solid var(--bs-border_two);
  border-radius: 0;
}

.form-control-error {
  border: var(--bs-border-width) solid var(--bs-input-error-generic) !important;
  border-radius: 0 !important;
}
.form-control-error:focus {
  border: var(--bs-border-width) solid var(--bs-input-error-generic) !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

// added the below to remove the default blue-color box shadow

.form-control:focus {
  border: var(--bs-border-width) solid var(--bs-border_two);
  border-radius: 0;
  box-shadow: none !important;
}

.css-1im77uy-control {
  border-color: var(--bs-border_two) !important;
}

/* Nabbar Header */
.header .navbar {
  background: var(--bs-brand-gradient);
  color: var(--bs-white);
  height: 73.46px;
}
.header .navbar .navbar-nav .nav-link {
  color: var(--bs-white);
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  padding: 0.938rem;
}
.tag.navbar-nav {
  margin-left: auto !important;
}
.login-button {
  padding: 0.5rem 0;
  cursor: pointer !important;
  z-index: 2;
  position: relative;
}
.header .dropdown-toggle::after {
  margin-left: 0.455em;
  vertical-align: 0.1em;
}

/* Footer */

.footer {
  color: var(--bs-white);
  padding: 1rem 0 2.5rem 0;
  margin-top: 3rem;
  background-image: url("../assest/monu_TEXTURE_Rectangle.png"),
    var(--bs-brand-gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.points {
  text-decoration: underline;
  color: var(--bs-italianfontcolor);
  margin: 0 auto;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.div-download-link {
  width: auto;
  height: auto;
}
.footer-menu-header span {
  padding: 0;
}
.footer-menu-header {
  margin-bottom: 1em;
  font-weight: 600;
}
.copywrite-text {
  margin-top: 2.5rem;
}
.footer-heading {
  margin-left: 80px;
}
.custom-footer-gap {
  gap: 266px;
}

/* Landing Screen*/

.banner_box {
  background: var(--bs-brand-gradient);
  color: var(--bs-white);
  height: calc(100% - 4.375rem);
  padding: 2rem;
}
.banner_box::after {
  content: ""; // ::before and ::after both require content
  background-image: url("../assest/monu_TEXTURE_Rectangle.png");
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-sec-dev {
  font-family: Roboto Flex;
  font-size: 62px;
  font-weight: 600;
  width: 100%;
  line-height: 73.5px;
  text-align: left;
}
.banner-sec-dev-italic {
  font-style: italic;
  font-family: Courgette;
  font-size: 59px !important;
}
.italian-font-color {
  color: var(--bs-italianfontcolor) !important;
}
.banner-thr-dev {
  width: 106%;
  line-break: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.banner-hr-block {
  width: 100%;
  text-align: left;
  margin-left: 0;
  border-top-width: 2px;
  color: white;
}

.hr {
  display: block;
  margin-top: 1em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
.getstarted_btn {
  z-index: 1;
  position: relative;
}
.welcome-img {
  width: auto;
  height: calc(100vh - 165px);
}

/* Landing Page offerings */

.offerings-cards,
.mission-card {
  margin-top: 2rem;
}
.offerings-cards .col-card:nth-child(odd),
.mission-card .col-card:nth-child(odd) {
  padding-right: 1rem;
}
.offerings-cards .col-card:nth-child(even),
.mission-card .col-card:nth-child(even) {
  padding-left: 1rem;
}
.offerings-cards .card,
.mission-card .card {
  background-color: var(--bs-light) !important;
  border-radius: 0;
  border: none;
  height: 100%;
}
.offerings-cards .card-body,
.mission-card .card-body {
  padding: 1rem 2rem;
}
.offerings-cards .card-title,
.mission-card .card-title {
  font-family: Roboto Flex;
  font-weight: 600;
}
.offerings-cards p.card-text,
.mission-card p.card-text {
  margin-bottom: 0.2rem;
}
.offerings-cards .card-link,
.mission-card .card-link {
  margin-top: 0.4rem;
}
.offerings-cards .card-subtitle,
.mission-card .card-subtitle {
  font-family: Roboto Flex;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-top: 1rem;
}
.bullet-height {
  height: 1.75em;
}
.ul-img-add {
  padding-left: 0%;
  list-style-type: none !important;
}
.mission-card ul li {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 1rem;
}
.mission-card ul li span.card-text {
  margin-left: 0.85rem;
}
/* Modal */

.modal-content {
  border-radius: 0;
  border-top: 5px solid var(--bs-primary-one);
}
.modal-content .modal-header {
  border: 0;
  padding: 1rem 2rem;
}
.modal-content .modal-footer {
  border: 0;
}

.modal-header .btn-close {
  border: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-radius: 0.938rem;
}

/* Get started Modal */

.getstarted-cards {
  margin: 0;
  padding: 0 1rem;
}
.getstarted-cards .col-card:nth-child(odd) {
  margin-right: 1rem;
  padding-right: 0;
}
.getstarted-cards .col-card:nth-child(even) {
  margin-left: 1rem;
  margin-right: 0;
  padding-left: 0;
}
.getstarted-cards .card {
  background-color: var(--bs-light) !important;
  border-radius: 0;
  border: none;
  min-height: 16.375rem;
}
.getstarted-cards .card-body {
  padding: 1rem 2rem;
}
.getstarted-cards .card-title {
  font-family: Roboto Flex;
  font-weight: 600;
}
.getstarted-cards p.card-text {
  margin-bottom: 0.2rem;
  font-size: 0.875rem;
}
.getstarted-cards .card-link {
  margin-top: 0.4rem;
}
.getstarted-cards a {
  font-size: 0.875rem;
}
.getstarted-cards .card-subtitle {
  font-family: Roboto Flex;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-top: 0.65rem;
}
.modal-backdrop {
  z-index: 1020 !important;
}

/* Select Savings Product & Stepper pages */
.wizard-stepper {
  margin: 0 0 32px 0;
  width: 100%;
}
.wizard-stepper tbody tr td {
  border: 0;
  padding: 0;
}
.wizard-stepper tbody tr td:nth-child(odd) {
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}
.square-stepper-button {
  height: 50px;
  width: 50px;
  z-index: -1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-step-next-fill);
  color: var(--bs-step-next-text);
  border: 1px solid var(--bs-step-next-border);
  font-size: 1.5rem;
}
.square-stepper-button.current {
  background-color: var(--bs-step-current);
  color: var(--bs-step-current-text);
  border: 1px solid var(--bs-step-current-border);
}
.square-stepper-button.complete {
  background-color: var(--bs-step-complete);
  color: var(--bs-step-complete-tick);
  border: 1px solid var(--bs-step-complete-border);
}
.line-stepper-button {
  width: 100%;
  height: 0;
  border: 1px solid var(--bs-step-next-line);
}
.line-stepper-button.complete {
  border: 1px solid var(--bs-step-completed-line);
}

#stepper-ul > li {
  list-style-type: disc !important;
}

.stepper-sub-product-dis-title {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.stepper-sub-product-title {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: left;
  margin-bottom: 1em;
}

.stepper-sub-product-value {
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 1em;
}
.step {
  border-radius: 0 !important;
}

.disable {
  border-radius: 0 !important;
  background-color: gray !important;
}

.plan-product-item {
  width: 23%;
  margin-right: 0.5em;
  margin-top: 9px;
  background-color: var(--bs-light);
}

.stepper-product {
  background-color: var(--bs-light);
  padding: 1rem 0.75rem;
}

.stepper-hr {
  margin-top: 1.5em;
  border: 1px solid var(--bs-black);
  opacity: 0.5;
}
.stepper-summary ul,
.stepper-summary ol {
  padding-left: 1rem;
}
.stepper-title {
  font-family: Roboto Flex;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
}

.stepper-product-label {
  margin-top: 1em;
}

.choose-product-title {
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
}

.stepper-product-value {
  font-family: Roboto Flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.44px;
  text-align: left;
  color: var(--bs-primary-one) !important;
  margin-top: 0.4em;
}

.formButtonBlocks {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}
.customcheckbox .form-check-input {
  border-radius: 0;
}
.customcheckbox .form-check-input:checked {
  background-color: var(--bs-primary-one);
  border-color: var(--bs-primary-one);
}
.customcheckbox .form-check-input:focus,
.customradio .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 81, 92, 0.15);
}
.customradio .form-check-input:checked {
  background-color: var(--bs-primary-one);
  border-color: var(--bs-primary-one);
}
.terms-conditions-choosePlan.openLinkItems {
  display: flex;
  justify-content: space-between;
}

/* Utility CSS */

.font-size-48px {
  font-size: 3rem;
}

.border-radius-0 {
  border-radius: 0;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-36px {
  font-size: 36px;
}

.font-size-24px {
  font-size: 24px;
}

.font-size-26px {
  font-size: 26px;
}

.font-size-28px {
  font-size: 28px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-light {
  font-weight:var(--font-weight-light);
}
.my-modal {
  width: 38vw ;   /* Occupy the 90% of the screen width */
  max-width: 90vw;
} 

.suggestion-box {
  border-top-style: none;
  border-color: #a7a7a7;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
}

/* Media Queries */
.customWidth921 {
  max-width: 921px !important;
}

.customWidth800 {
  max-width: 800px !important;
}

.margin-left-one {
  margin-left: 1em !important;
}

.otp-input-box {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  text-align: center;
  border: 0px 0px px 0px;
  top: 139px;
  gap: 0px;
  border: none;
  background: #f2f6f7;
  border-bottom: 1px solid #66969c;
}

.otp-tick-mark {
  margin-top: 0.3em;
  margin-left: -23px !important;
  z-index: 1;
}

.otp-tick-mark-img {
  margin-left: -31px;
  position: relative;
  z-index: 1;

}

// input border
.input-error-border {
  border: 1px solid var(--bs-input-error-border) !important;
}

// width for global usage
.width-100-percent {
  width: 100%;
}

.width-50-percent {
  width: 50%;
}

.width-48-percent {
  width: 48%;
}

.point-btn {
  text-decoration: underline;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: right;
  color: #00505a;
}
